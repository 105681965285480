* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

*:not(b) {
    font-weight: 300;
}

::selection {
    background-color: #4295b0;
    color: white;
}

body {
    min-width: fit-content;
}

a {
    color: #4295b0;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    color: white;
    background-color: #4295b0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-weight: 400;
    font-size: 1.3em;
    padding: 10px 20px;
    border-color: transparent;
    cursor: pointer;
}

@media screen and (min-width: 1000px) {
    .button.adjust {
        width: fit-content;
    }
}

.red-button {
    background-color: #c7135d;
}

ul {
    padding-left: 30px;
}

form,
form div,
form div div {
    display: flex;
    flex-direction: column;
}

form > div,
form div > div {
    margin-bottom: 15px;
}

form ul, 
form li {
    list-style: none;
    color: #c7135d;
    font-weight: 500;
    font-size: 0.9em;
    padding: 0;
}

form .help-text {
    margin-top: 5px;
    font-style: italic;
    text-align: right;
}

label {
    margin: 5px 0;
    display: none;
}

@media screen and (min-width: 700px) {
    label {
        display: initial;
        font-weight: bold;
    }
}

input,
select {
    border-radius: 3px;
    border: solid 1px #777777;
    font-weight: 400;
    font-size: 1.3em;
    color: #777777;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
}

input:focus,
.button:focus {
    outline: none;
    filter: drop-shadow(0px 0px 5px #4295b0);
}

.red-button:focus {
    filter: drop-shadow(0px 0px 5px #c7135d);
}

@media screen and (min-width: 700px) {
    input {
        border: solid 1px black;
    }
    
    input::placeholder {
        color: transparent;
    }
}

h1,
h2,
h3 {
    text-align: center;
}

h1 {
    margin: 15px 0 40px 0;
    font-weight: 500;
    font-size: 1.5em;
}

h2 {
    margin: 30px 0 15px 0;
    font-weight: 500;
    font-size: 1.2em;
}

h3 {
    margin: 30px 0 15px 0;
    font-weight: 400;
    font-size: 1.1em;
}

h4 {
    margin: 20px 0 5px 0;
    font-weight: 400;
    font-size: 1em;
}

.justified {
    text-align: justify;
}

.required-text {
    display: none;
}

@media screen and (min-width: 700px) {
    .required-text {
        color: #c7135d;
        font-weight: 300;
        display: initial;
    }
}

.required::after {
    content: '*';
    color: #c7135d;
}

.search-bar {
    display: flex;
    position: relative;
    margin-bottom: 15px;
}

.search-bar input[type="text"] {
    border-radius: 30px;
    width: 80vw;
    padding-right: 50px;
    box-sizing: border-box;
}

@media screen and (min-width: 700px) {
    .search-bar input[type="text"] {
        width: 50vw;
    }
}

.search-bar input[type="button"] {
    border: transparent;
    background-color: transparent;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27px;
    cursor: pointer;
    width: auto;
}

.search-bar input[type="button"] {
    right: 10px;
    background-image: url("/pictures/cross.svg");
    filter: brightness(0);
}

.push-to-bottom {
    flex-grow: 1;
}

.clipboard {
    position: relative;
}

.clipboard img {
    width: 15px;
    height: 21px;
    margin-left: 7px;
    margin-bottom: 10px;
    cursor: pointer;
}

.clipboard-copied {
    position: absolute;
    top: -30px;
    width: 50px;
    background-color: #d2d2d2;
    padding: 3px;
    border-radius: 3px;
}


.popup {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.85);
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    transform: scale(0);
    filter: opacity(0);
    transition: transform 0.01s ease-out 0.3s,
                filter 0.3s ease-out;
}

.popup.opened {
    transform: scale(1);
    filter: opacity(1);
    transition: transform 0.01s ease-out,
                filter 0.3s ease-out;
}

.popup-container {
    background-color: white;
    height: 300px;
    width: 500px;
    transform: scale(1.1);
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.popup.opened .popup-container {
    transform: scale(1);
}

.popup-text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.popup-buttons {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 700px) {
    .popup-buttons {
        flex-direction: row;
    }
}

.popup-button {
    flex-grow: 1;
    margin: 5px;
    text-align: center;
}

.no-display {
    display: none;
}

.cart-button {
    color: white;
    background-color: #4295b0;
    font-size: 1.3em;
    font-weight: 400;
    border-radius: 100px;
    padding: 15px 35px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-out;
}

@media screen and (min-width: 1000px) {
    .cart-button:hover {
        filter: drop-shadow(0 0 5px #4295b0);
    }
}

.cart-button img {
    width: 40px;
    margin-left: 20px;
    filter: drop-shadow(-2px 5px 3px rgba(0, 0, 0, 0.5));
}